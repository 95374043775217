import React, { useEffect, useState } from "react";
import JiraIcon from "@/icons/JiraIcon";
import GlobalButton from "@/components/ui/GlobalButton";
import GlobalDropdown from "@/components/ui/GlobalDropdown";
import useAuthStore from "@/store/Auth";
import WebAppService from "@/services/WebAppService";
import { Link } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import ErrorToast from "@/components/modals/ErrorToast";
import JiraStatusModal from "./JiraStatusModal";

export default function SendToJiraModal({ onClose, type, projectId }) {
	const [jiraProjectKeys, setJiraProjectKeys] = useState([]);
	const [selectedProjectKey, setSelectedProjectKey] = useState("");
	const [jiraStatusModal, setJiraStatusModal] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [errorToast, setErrorToast] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [error, setError] = useState(null);
	const authStore = useAuthStore();
	const navigate = useNavigate();

	const { organization } = authStore;
	const { hasSetJiraConfig } = organization;

	const { user } = authStore;
	const organizationId = user.organization;
	const jira_url = organization.jira_url;

	const closeJiraStatusModal = () => {
		setJiraStatusModal(false);
	};

	useEffect(() => {
		WebAppService.getOrganizationJiraKey(organizationId)
			.then((response) => {
				const jiraKeys = response.data.values.map((project) => ({
					label: `${project.key} - ${project.name}`,
					value: project.key,
				}));

				setJiraProjectKeys(jiraKeys);
			})
			.catch((error) => {
				setError(error);
				console.error("Error getting Jira keys", error);
			});
	}, [organizationId]);

	const handleSubmit = async (event) => {
		event.preventDefault();
		const project_key = selectedProjectKey?.value;

		if (projectId && project_key) {
			setIsSubmitting(true);
			try {
				const sendProjectToJiraResponse = await WebAppService.sendProjectToJira(
					projectId,
					{ project_key: project_key }
				);

				// console.log(sendProjectToJiraResponse);

				if (sendProjectToJiraResponse?.success === true) {
					setFormSubmitted(true);
					// Close the modal after 5 seconds
					setTimeout(() => {
						onClose();
					}, 5000);
				} else {
				}
			} catch (error) {
				setErrorMessage(
					error?.response?.data?.error ||
						"Something went wrong. Please try again"
				);
				setTimeout(() => {
					setErrorToast(true);
				}, 1000);
				// setIsSubmitting(false);

				setTimeout(() => {
					setErrorToast(false);
				}, 4000);

				setTimeout(() => {
					onClose();
				}, 4000);
			}
		}
	};

	return (
		<div className="modal">
			<div
				onClick={(event) => {
					event.stopPropagation();
					onClose();
				}}
				className="modal-overlay absolute inset-0 bg-black-1/40 opacity-90"></div>
			{formSubmitted ? (
				<JiraStatusModal
					type={type}
					onClose={onClose}
				/>
			) : (
				<div className="modalBg overflow-scroll px-8 py-10 w-[90%] md:w-[90%] lg:w-[40%] xl:max-w-[700px] max-h-[90%] h-fit top-1/2 left-1/2 absolute border-0.5 border-gray-2/50 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-7 items-center rounded-lg shadow-md text-white-1">
					<div className="flex flex-col gap-3 items-center justify-center text-center">
						<div className="flex gap-2 items-center justify-center">
							<JiraIcon />
							<h2 className="font-semibold text-[20px]">Send {type} to Jira</h2>
						</div>
						{hasSetJiraConfig ? (
							<p className="text-gray-4 text-[14px]">
								You are about to send this {type} to Jira. Please select your
								Jira project below.
							</p>
						) : (
							<p className="text-gray-4 text-[14px]">
								Crafter is not yet configured to send tickets into your Jira
								project. You have to set up Jira in the integrations page before
								continuing.
							</p>
						)}
					</div>

					{hasSetJiraConfig ? (
						<form
							onSubmit={handleSubmit}
							className="w-full flex flex-col gap-2 mt-3">
							{error ? null : (
								<div
									onClick={(event) => {
										event.stopPropagation();
									}}
									className="font-semibold text-white-2">
									<GlobalDropdown
										isRequired={true}
										label={`Select your project`}
										placeholder={`Select Jira project key`}
										options={jiraProjectKeys}
										value={selectedProjectKey}
										onChange={setSelectedProjectKey}
										disabled={error}
									/>
								</div>
							)}
							{error && (
								<p className="text-gray-4 text-center  mt-3">
									You do not have any Jira Projects.{" "}
									<Link
										to={jira_url}
										className="text-blue-1 font-semibold underline underline-offset-4"
										target="_blank"
										rel="noopener noreferrer">
										Create here
									</Link>
								</p>
							)}
							{error ? null : (
								<div className={"mt-4 flex items-center gap-8 w-full"}>
									<div
										onClick={(event) => {
											event.stopPropagation();
										}}
										className="flex w-full">
										<GlobalButton
											type={"submit"}
											variant={"blue"}
											size={"lg"}
											state={isSubmitting ? "inactive" : "default"}>
											{isSubmitting ? "Sending..." : `Send ${type} to Jira`}
										</GlobalButton>
									</div>
									<GlobalButton
										onClick={(event) => {
											event.stopPropagation();
											onClose();
										}}
										variant={`redStroke`}
										state={`default`}
										size={`lg`}
										className={``}>
										Cancel
									</GlobalButton>
								</div>
							)}
						</form>
					) : (
						<div
							className={
								"mt-4 flex items-center justify-center mx-auto gap-8 w-full"
							}>
							<GlobalButton
								onClick={(event) => {
									event.stopPropagation();
									navigate("/integrations");
								}}
								variant={"blue"}
								size={"lg"}
								state={"default"}>
								Integrate your Jira
							</GlobalButton>

							<GlobalButton
								onClick={(event) => {
									event.stopPropagation();
									onClose();
								}}
								variant={`redStroke`}
								state={`default`}
								size={`lg`}
								className={``}>
								Cancel
							</GlobalButton>
						</div>
					)}
				</div>
			)}

			{/* {jiraStatusModal && <JiraStatusModal />} */}
			{errorToast && <ErrorToast message={errorMessage} />}
		</div>
	);
}
