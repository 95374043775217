import React from "react";
import { useRef, useState, useEffect } from "react";
import FileIcon from "@/icons/FileIcon";
import InputField from "@/components/ui/InputField";
import InputField2 from "@/components/ui/InputField2";
import GlobalButton from "@/components/ui/GlobalButton";
import GlobalCheckbox from "@/components/ui/GlobalCheckbox";
import InfoCircle from "@/icons/InfoCircle";
import CloseIcon from "@/icons/CloseIcon";
import PdfIcon from "@/icons/files/PdfIcon";
import TxtIcon from "@/icons/files/TxtIcon";
import DocIcon from "@/icons/files/DocIcon";
import DocxIcon from "@/icons/files/DocxIcon";
import CheckIcon from "@/icons/CheckIcon";
import UpLoad from "@/icons/UpLoad";
import StatusModal from "./StatusModal";
import WebAppService from "@/services/WebAppService";
import useAuthStore from "@/store/Auth";
import SuccessToast from "@/components/modals/SuccessToast";
import ErrorToast from "@/components/modals/ErrorToast";
import WebpIcon from "@/icons/files/WebpIcon";
import PngIcon from "@/icons/files/PngIcon";
import JpgIcon from "@/icons/files/JpgIcon";
import JpegIcon from "@/icons/files/JpegIcon";
import InputField3 from "@/components/ui/InputField3";

import { useProjects } from "../../context/ProjectsContext";
import { useToast } from "../../context/ToastContext";
import Uploaad from "../../icons/Uploaad";

export default function UploadDocModal({
	onClose,
	onCreateTickets,
	sentProjects,
	setSentProjects,
	setCreateTicketsResponse,
}) {
	const [formSubmitted, setFormSubmitted] = useState(false);
	const { refetchProjects } = useProjects();
	const { addToast } = useToast();

	const fileInputRef = useRef(null);
	const [dragging, setDragging] = useState(false);

	const handleFileInputClick = () => {
		fileInputRef.current.click();
	};
	const [formValid, setFormValid] = useState(false);
	const [files, setFiles] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [uploadErrorMsg, setUploadErrorMsg] = useState("");
	const [showUploadErrorMsg, setShowUploadErrorMsg] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [showProgress, setShowProgress] = useState(false);
	const [successToast, setSuccessToast] = useState(false);
	const [errorToast, setErrorToast] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [showSection, setShowSection] = useState(false);

	const [formData, setFormData] = useState({
		name: "",
		description: "",
		file: null,
		acceptance_criteria: false,
		test_cases: false,
	});

	const authStore = useAuthStore();
	const organizationId = authStore.user.organization;

	const fileIcons = {
		pdf: PdfIcon,
		txt: TxtIcon,
		doc: DocIcon,
		docx: DocxIcon,
		jpg: JpgIcon,
		jpeg: JpegIcon,
		png: PngIcon,
		webp: WebpIcon,
	};

	useEffect(() => {
		if (uploadedFiles.length > 0) {
			setShowSection(true);
		}
	}, [uploadedFiles]);

	useEffect(() => {
		// Check if all required fields have values
		if (formData.name && formData.file) {
			setFormValid(true);
		} else {
			setFormValid(false);
		}
	}, [formData]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (!file) return;

		const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
		if (file.size > maxFileSize) {
			// File size exceeds the maximum allowed size
			console.error("File size exceeds the maximum allowed size (10MB).");
			addToast(
				"File size exceeds the maximum allowed size (10MB)",
				"warning",
				3000
			);

			return;
		}

		const fileNameWithoutExtension = file.name.split(".")[0]; // Get the filename without extension
		const fileExtension = file.name.split(".")[1];

		const fileName =
			file.name.length > 12
				? `${fileNameWithoutExtension.substring(0, 13)}...${fileExtension}`
				: file.name;

		// Set file data to state
		setFormData({
			...formData,
			file: file,
		});
		// Set files state for UI display
		setFiles([{ name: fileName, loading: 0 }]);
		// Start uploading process
		setUploading(true);

		// Simulate file upload progress
		let progress = 0;
		const interval = setInterval(() => {
			progress += 20;
			setFiles((prevState) => {
				const newFiles = [...prevState];
				newFiles[0].loading = progress;
				return newFiles;
			});
			if (progress >= 100) {
				clearInterval(interval);
				const fileSize =
					file.size < 1024
						? `${file.size} bytes`
						: file.size < 1024 * 1024
						? `${(file.size / 1024).toFixed(2)} KB`
						: `${(file.size / (1024 * 1024)).toFixed(2)} MB`;
				setUploadedFiles([{ name: fileName, size: fileSize }]);
				setUploading(false);
			}
		}, 500);
	};

	const handleCheckboxChange = (id, isChecked) => {
		setFormData({
			...formData,
			[id]: isChecked,
		});
	};

	// const handleSubmit = async (e) => {
	// 	e.preventDefault();

	// 	console.log(formData);

	// 	setIsSubmitting(true);
	// 	// // Create a new FormData object
	// 	const formDataToSend = new FormData();
	// 	// // Append all fields from formData to formDataToSend
	// 	Object.entries(formData).forEach(([key, value]) => {
	// 		formDataToSend.append(key, value);
	// 	});

	// 	// console.log(formDataToSend);

	// 	try {
	// 		const createTicketResponse = await WebAppService.createTicket(
	// 			formDataToSend,
	// 			organizationId
	// 		);

	// 		// console.log(createTicketResponse);

	// 		setCreateTicketsResponse(createTicketResponse);
	// 		setFormSubmitted(true);

	// 		setSentProjects((prevProjects) => [...prevProjects, formData]);
	// 		refetchProjects();
	// 		onCreateTickets();
	// 	} catch (error) {
	// 		throw error;
	// 	}

	// 	// Close the modal after 5 seconds
	// 	setTimeout(() => {
	// 		setIsSubmitting(false);
	// 		onClose();
	// 	}, 2000);
	// };

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log(formData);
		setIsSubmitting(true);
	
		// Create a new FormData object
		const formDataToSend = new FormData();
		
		//  append the required fields
		formDataToSend.append("name", formData.name);
		formDataToSend.append("description", formData.description);
		formDataToSend.append("file", formData.file);
	
		// Conditionally append optional fields if they are selected
		if (formData.acceptance_criteria) {
			formDataToSend.append("acceptance_criteria", formData.acceptance_criteria);
		}
		if (formData.test_cases) {
			formDataToSend.append("test_cases", formData.test_cases);
		}
	
		try {
			const createTicketResponse = await WebAppService.createTicket(
				formDataToSend,
				organizationId
			);
	
			setCreateTicketsResponse(createTicketResponse);
			setFormSubmitted(true);
			setSentProjects((prevProjects) => [...prevProjects, formData]);
			refetchProjects();
			onCreateTickets();
		} catch (error) {
			throw error;
		}
	
		// Close the modal after 5 seconds
		setTimeout(() => {
			setIsSubmitting(false);
			onClose();
		}, 2000);
	};
	
	const handleDragOver = (e) => {
		e.preventDefault();
		setDragging(true);
	};

	const handleDragEnter = (e) => {
		e.preventDefault();
		setDragging(true);
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		setDragging(false);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		setDragging(false);

		const files = e.dataTransfer.files;
		if (files.length > 0) {
			handleFiles(files);
		}
	};

	const handleFiles = (files) => {
		const file = files[0];
		if (file) {
			handleFileChange({ target: { files: [file] } });
		}
	};

	const getFileExtension = (fileName) => {
		return fileName.split(".").pop().toLowerCase();
	};

	return (
		<div className="modal">
			<div
				onClick={onClose}
				className="modal-overlay absolute inset-0 bg-black-1 opacity-100"></div>

			{formSubmitted ? (
				<StatusModal onClose={onClose} />
			) : (
				<form
					onSubmit={handleSubmit}
					className="flex flex-col rounded-xl modalBg z-20 w-[90%] md:w-[90%] lg:w-[50%] max-h-[90%] py-[48px] px-8  md:px-16 lg:px-[48px] overflow-scroll">
					<div className="flex flex-col gap-y-8 items-start justify-start">
						<div className="flex items-center justify-between w-full">
							<div className="flex flex-col items-start w-4/5">
								<h2 className="flex gap-2 items-center text-2xl font-bold text-gradient text-left">
									<FileIcon />
									New project
								</h2>
							</div>

							<span
								onClick={onClose}
								className="cursor-pointer">
								<CloseIcon />
							</span>
						</div>
					</div>
					<div className="flex flex-col gap-2 mt-10">
						<InputField2
							name="name"
							value={formData.name}
							onChange={handleChange}
							placeholder={"Enter project title"}
						/>
						<InputField3
							name="description"
							value={formData.description}
							onChange={handleChange}
							placeholder={"Project description (Optional)"}
						/>
					</div>

					{uploading ? (
						<section className="loading-area w-[70%] mx-auto mt-10 cursor-pointer px-[16px] py-[16px] rounded-md">
							{files.map((file, index) => {
								const fileExtension = getFileExtension(file.name);
								const IconComponent = fileIcons[fileExtension];

								return (
									<li
										className="row flex flex-col gap-5"
										key={index}>
										<div className="flex items-center gap-5">
											{IconComponent && <IconComponent />}
											<span className="name">{`${file.name}`}</span>
										</div>
										<div className="content">
											<div className="details flex gap-3">
												<div className="loading-bar">
													<div
														className="loading"
														style={{ width: `${file.loading}%` }}></div>
												</div>
												<span className="percent">{`${file.loading}%`}</span>
											</div>
										</div>
									</li>
								);
							})}
						</section>
					) : uploadedFiles.length > 0 ? (
						<section className="uploaded-area w-[70%] mx-auto mt-10 cursor-pointer px-[16px] py-[16px] rounded-md">
							{uploadedFiles.map((file, index) => {
								const fileExtension = getFileExtension(file.name);
								const IconComponent = fileIcons[fileExtension];
								return (
									<li
										className="row"
										key={index}>
										<div className="flex items-start text-white-1 flex-col mx-auto content upload">
											<div className="flex items-center gap-5">
												{IconComponent && <IconComponent />}
												<div className="flex flex-col gap-1">
													<span className="name">{file.name}</span>
													<span className="size">{file.size}</span>
												</div>
											</div>
											<div className="details flex items-center justify-center"></div>
										</div>
										<div className="text-green-700">
											<CheckIcon />
										</div>
									</li>
								);
							})}
						</section>
					) : null}

					<div
						className={`upload-area ${
							uploading || uploadedFiles.length > 0 ? "hidden" : ""
						} ${
							dragging ? "dragging" : ""
						} w-[75%] mx-auto mt-10 cursor-pointer px-[24px] py-[24px] rounded-md`}
						onDragOver={handleDragOver}
						onDragEnter={handleDragEnter}
						onDragLeave={handleDragLeave}
						onDrop={handleDrop}
						onClick={handleFileInputClick}>
						<input
							type="file"
							name="file"
							accept=".txt, .pdf, .doc, .docx, .jpg, .jpeg, .png, .webp"
							onChange={handleFileChange}
							ref={fileInputRef}
						/>
						<div className="flex flex-col items-center w-full">
							<UpLoad />
							<h3 className="text-center lg:text-[16px] xl:text-[16px]  text-gray-2">
								<span className="font-semibold text-blue-2">
									Click to upload{" "}
								</span>
								or drag and drop
							</h3>
							<p className="text-center w-5/5 text-gray-6 text-sm mt-4">
								File should be in the following format .TXT, .PDF, .DOCX (Word),
								.JPG, .WEBP and .PNG
								<span className="text-orange-1">
									{" "}
									(Upload is maximum of 10mb and 15 pages)
								</span>
							</p>
						</div>
					</div>

					{/* {uploadErrorMsg && (
						<p className="text-error-2 text-center italic">
							File size exceeds the maximum allowed size (10MB).
						</p>
					)} */}

					{/* --------- could be used later ------------*/}
					{/* {uploadedFiles.length > 0 ? ( */}
					<section
						className={`${
							showSection ? "max-h-[500px] mt-10" : "max-h-0 overflow-hidden"
						} flex flex-col  transition-max-height duration-500 ease-in-out`}>
						<div>
							<div className="flex flex-col gap-1 items-center justify-center">
								<label className="flex  items-end gap-1 font-semibold text-[20px]">
									<span>Tickets format </span>
									<span className="text-gray-6 text-[12px]">(Optional) </span>
									<span>
										<InfoCircle />
									</span>
								</label>
								<p className="text-sm text-gray-6">
									Tell us the sections you want to include in the tickets
								</p>

								<div className="mt-3 flex gap-x-10 gap-y-3 items-center justify-start flex-row overflow-hidden w-full flex-wrap">
									<div className="flex mx-auto gap-3">
										<GlobalCheckbox
											id="acceptance_criteria"
											label="Acceptance Criteria"
											isChecked={formData.acceptance_criteria}
											onChange={handleCheckboxChange}
										/>

										<GlobalCheckbox
											id="test_cases"
											label="Test Cases"
											isChecked={formData.test_cases}
											onChange={handleCheckboxChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* ) : null} */}

					<div className="w-[70%] mx-auto mt-10">
						<GlobalButton
							variant={"secondary"}
							size={"lg"}
							state={isSubmitting || !formValid ? "inactive" : "default"}
							type={"submit"}
							className=" flex items-center gap-2 justify-center font-semibold text-sm ">
							{isSubmitting ? "Generating..." : "Generate tickets"}
						</GlobalButton>
					</div>
				</form>
			)}

			{successToast && <SuccessToast message={successMessage} />}
			{errorToast && <ErrorToast message={errorMessage} />}
		</div>
	);
}
