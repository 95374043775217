import React, { useState } from "react";
import CrafterLogo from "@/icons/CrafterLogo";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import LoginForm from "./LoginForm";
import CheckYourMail from "./CheckYourMail";
import VerifyOTP from "./VerifyOTP";

export default function Login() {
	const [activeComponent, setActiveComponent] = useState("LoginForm");
	const [submissionSuccess, setSubmissionSuccess] = useState(false);
	const [enteredEmail, setEnteredEmail] = useState("");

	const handleLoginFormSuccess = () => {
		setSubmissionSuccess(true);
		setActiveComponent("CheckYourMail");
	};

	const handleCheckYourMailContinue = () => {
		setActiveComponent("VerifyOTP");
	};

	const handleEmailEntered = (email) => {
		setEnteredEmail(email);
	};

	return (
		<main className="bg-black-6 text-gray-4 h-screen w-full flex flex-col gap-y-[20px] py-[30px] px-[30px] lg:px-[100px] md:px-[50px]">
			<Helmet>
				<title>Log in to Crafter</title>
				<meta
					name="description"
					content="Log in to Crafter to access your personalized workspace, manage projects, and collaborate efficiently with your team."
				/>
			</Helmet>
			<Link
				to={"https://usecrafter.com"}
				target="_blank">
				<CrafterLogo />
			</Link>
			{activeComponent === "LoginForm" && (
				<LoginForm
					setSubmissionSuccess={handleLoginFormSuccess}
					onEmailEntered={handleEmailEntered}
				/>
			)}
			{activeComponent === "CheckYourMail" && (
				<CheckYourMail
					enteredEmail={enteredEmail}
					onContinue={handleCheckYourMailContinue}
				/>
			)}
			{activeComponent === "VerifyOTP" && (
				<VerifyOTP enteredEmail={enteredEmail} />
			)}
		</main>
	);
}
